import Params from 'flights/lib/params.js'
import DOMComponent from 'shared/components/base'

export default class SubscriptionClean extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'subscription_clean',
    }
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    if (LOCALE !== 'ru') return containerNode.parentNode.parentNode.removeChild(containerNode.parentNode)
    var script = document.createElement('script')
    script.setAttribute('charset', 'UTF-8')
    script.setAttribute('async', true)
    let originIata = (Params.affiliate.default_origin && Params.affiliate.default_origin.iata) || 'LED'
    let originName = (Params.affiliate.default_origin && Params.affiliate.default_origin.name) || originIata
    let destinationIata = (Params.affiliate.default_destination && Params.affiliate.default_destination.iata) || 'MOW'
    let destinationName = (Params.affiliate.default_destination && Params.affiliate.default_destination.name) || destinationIata
      script.src = `//www.travelpayouts.com/subscription_widget/widget.js?highlight=false&hide_alert=true&marker=${Params.affiliate.affiliate.marker}&host=${
        Params.affiliate.host
      }&originIata=${originIata}&originName=${originName}&destinationIata=${destinationIata}&destinationName=${destinationName}&powered_by=false&primary=${encodeURIComponent(
        window.TPWLCONFIG.color_scheme.btn_bg
      )}&secondary=${encodeURIComponent(window.TPWLCONFIG.color_scheme.bg)}`;
    containerNode.classList.add(`tpwl-${this.options.name}`)
    containerNode.appendChild(script)
  }
}
