import _merge from 'lodash/merge';
import ComponentsInitializer from 'shared/main.js';
import router from 'shared/lib/router.js';

class FlightsComponentsInitializer extends ComponentsInitializer {
  componentsMap() {
    let flightComponents = {
      'adsense': require('flights/components/google/adsense.coffee'),
      'airports_precheck': require('flights/components/airports_precheck/airports_precheck.js').default,
      'best_tickets': require('flights/components/best_tickets/best_tickets.js').default,
      'calendar': require('flights/components/calendar/round_trip/calendar.js').default,
      'calendar_one_way': require('flights/components/calendar/one_way/calendar_one_way.js').default,
      'calendar_line_one_way': require('flights/components/calendar/inline_one_way/calendar_line.js').default,
      'calendar_line_round_trip': require('flights/components/calendar/inline_round_trip/calendar_line.js').default,
      'ducklett': require('flights/components/ducklett/ducklett.js').default,
      'expired_search': require('flights/components/expired_search/expired_search.js').default,
      'fake_ticket': require('flights/components/fake_ticket/fake_ticket.js').default,
      'filters': require('flights/components/filters/filters.js').default,
      'filters_wrapper': require('flights/components/filters/filters_wrapper/filters_wrapper.js').default,
      // 'god_mode': require('flights/components/god_mode/god_mode.coffee'),
      'footer_pagination': require('flights/components/footer_pagination/pagination.js').default,
      'highlighted_ticket': require('flights/components/highlighted_ticket/highlighted_ticket.js').default,
      'mewtwo': require('flights/components/mewtwo/mewtwo.js').default,
      'search_history': require('flights/components/search_history/search_history.js').default,
      'search_results': require('flights/components/search_results/search_results.coffee'),
      'show_sorting_button': require('flights/components/show_sorting_button/show_sorting_button.coffee').default,
      'sticky_informer': require('flights/components/sticky_informer/sticky_informer.coffee').default,
      'subscription': require('flights/components/subscription/subscription.js').default,
      'subscription_clean': require('flights/components/subscription_clean/subscription_clean.js').default,
      'weedle': require('flights/components/weedle/weedle.js').default,
      'system_messages': require('flights/components/system_messages/system_messages.coffee'),
      'google_recaptcha': require('flights/components/google_recaptcha/google_recaptcha.coffee')
    }

    return _merge(super.componentsMap(), flightComponents)
  }

  specificRequirements() {
    require('flights/components/params_parser/parse.coffee')
    require('flights/components/request/dispatcher_interaction.coffee')
  }
}
router.page = 'serp'
let loadCallback = function () { new FlightsComponentsInitializer() }
if (window.TPWLCONFIG !== undefined && window.TPWLCONFIG.pageLoaded) loadCallback()
else document.addEventListener('DOMContentLoaded', loadCallback)
