_reduce = require('lodash/reduce')
_map = require('lodash/map')
_includes = require('lodash/includes')
_map = require('lodash/map')
moment = require('moment')
queryString = require('shared/lib/query_string.js')

date_picker_format = ['D MMMM YYYY', 'DD.MM.YY', 'DD.MM.YYYY', 'YYYY-MM-DD', 'YYYY.MM.DD']

TRIP_CLASS_MAP =
  '0': 'Y'
  '1': 'C'
  'Y': 'Y'

SEGMENT_KEY_MAP =
  destination: 'destination_name'
  destination_iata: 'destination'
  origin: 'origin_name'
  origin_iata: 'origin'
  depart_date: 'date'

dehumanise_date = (value) ->
  if(value.search(' ') != -1)
    value_parts = value.split(' ')
    if (moment(value, date_picker_format).format('D MMMM').split(" ")[1] == value_parts[1])
      month_num = ('0' + (moment(value, date_picker_format).month() + 1)).slice(-2)
      value = "#{value_parts[0]}-#{month_num}-#{value_parts[2]}"
    else
      today_date = new Date()
      value = "#{today_date.getDate()}-#{today_date.getMonth() + 1}-#{today_date.getFullYear()}"

  moment.ISODate(moment(value, date_picker_format))

params_map = {
  '^(with_request|range|one_way|oneway|auto_subscribe)$': (p, matches, value) ->
    p[matches[1]] = _includes(['true', true, '1'], value)

  '^(utm_source|marker|show_subscriptions)$': (p, matches, value) ->
    p[matches[1]] = value

  '^(trip_class)$': (p, matches, value) ->
    p[matches[1]] = TRIP_CLASS_MAP[value]

  '^(debug)$': (p, matches, value) ->
    p.debug = true

  '^(currency)$': (p, matches, value) ->
    p.currency = value.toLowerCase()

  '^(adults|children|infants)$': (p, matches, value) ->
    p['passengers'] or= {}
    p['passengers'][matches[1]] = parseInt(value, 10)

  #affiliate forms
  '^(destination_iata|origin_iata)$': (p, matches, value) ->
    [key, _trash] = matches[1].split('_')
    p.segments ?= [{}]
    p.segments[0][key] = value

  '^((destination|origin)(_name)?)$': (p, matches, value) ->
    p.segments ?= [{}]
    p.segments[0]["#{matches[1]}_name"] = value

  '^depart_date$': (p, matches, value) ->
    p.segments or= [{}]
    p.segments[0].date = dehumanise_date value

  '^noads': (p, matches, value) -> p[matches[0]] = value

  '^return_date$': (p, matches, value) ->
    p.return_date = dehumanise_date value

  '^depart_date_formatted$': (p, matches, value) ->
    p.segments or= [{}]
    p.segments[0].date = dehumanise_date value

  '^return_date_formatted$': (p, matches, value) ->
    p.return_date = dehumanise_date value

  '^segments\\\[([0-9]+)\\\]\\\[([a-z\_]+)\\\]$': (p, matches, value) ->
    p['segments'] or= []

    idx = +matches[1]
    p['segments'][idx] or= {}

    key = SEGMENT_KEY_MAP[matches[2]] || matches[2]
    p['segments'][idx][key] = value

  '^ticket$': (p, matches, value) ->
    p['ticket'] = value
    p

  '^(expected_price(_uuid|_currency|_source)?)$': (p, matches, value) ->
    p['expected_price'] or= {}
    key = {
      'expected_price': 'value'
      'expected_price_currency': 'currency'
      'expected_price_uuid': 'uuid'
      'expected_price_source': 'source'
    }[matches[1]]

    p['expected_price'][key] = value
    p

  '^utm_.*$': (p, matches, value) ->
    p["flags"] ||= {}
    p["flags"]["utmDetail"] ||= []
    p["flags"]["utmDetail"].push("#{matches[0]}=#{value}")
    p
}

module.exports = (location) ->
  params = queryString.parse(location)
  parsed_params = {}
  parsed_params.locale = params.locale if params.locale

  parsed_params.host = params.host if params.host
  parsed_params.preview = params.preview if params.preview

  for param_name, value of params
    #prepare oldest params
    param_name = param_name.replace(/search\[(\w+)\]/, '$1') if param_name.match(/^search\[.*\]$/g)
    for regex, callback of params_map
      reg = new RegExp(regex, 'g')
      matches = reg.exec(param_name)
      if matches? && value
        callback(parsed_params, matches, value)

  if parsed_params.return_date
    segment = parsed_params.segments[0]
    parsed_params.segments.push(
      origin: segment.destination
      origin_name: segment.destination_name
      destination: segment.origin
      destination_name: segment.origin_name
      date: parsed_params.return_date
    )
    delete parsed_params.return_date

  if parsed_params.oneway
    parsed_params.one_way = parsed_params.oneway
    delete parsed_params.oneway

  if parsed_params.one_way
    delete parsed_params.one_way
    parsed_params.segments = [parsed_params.segments[0]]

  if parsed_params.expected_price and !parsed_params.expected_price.source
    parsed_params.expected_price.source = parsed_params.utm_source

  if parsed_params["flags"] && parsed_params["flags"]["utmDetail"] && parsed_params["flags"]["utmDetail"].length
    parsed_params["flags"]["utmDetail"] = parsed_params["flags"]["utmDetail"].join('&')

  parsed_params
