_cloneDeep = require('lodash/cloneDeep')
_extend = require('lodash/extend')
_isEmpty = require('lodash/isEmpty')
_forEach = require('lodash/forEach')
moment                = require('moment')
dispatcher            = require('shared/lib/dispatcher')
PlacesRestorer        = require('./places_restorer.coffee')
utils                 = require('shared/lib/utils.coffee')
cookies               = require('shared/lib/cookies.coffee')
marker                = require('shared/lib/marker.coffee')
metrics               = require('shared/lib/metrics.coffee')
cookie_params         = require('flights/lib/cookie_params.coffee')
converter             = require('shared/lib/converter.js')
search_params         = require('./search_params.coffee')
preview_params_parser = require('shared/lib/preview_params.coffee')

DEFAULT_CURRENCY = 'usd'

is_valid_dates = (params) ->
  now_moment = moment()
  depart_moment = moment(params.segments[0].date, 'YYYY-MM-DD')
  valid = depart_moment.diff(now_moment, 'days') > -1

  if valid and params.segments.length > 1
    prev_moment = depart_moment
    for segment in params.segments
      next_moment = moment(segment.date)
      valid = next_moment.diff(prev_moment, 'days') >= 0
      if !valid
        return valid
      prev_moment = next_moment

  valid

update_params = (params) ->
  _forEach params.segments, (segment, index) => segment.date = moment().add('days', 7 * (index+1)).format('YYYY-MM-DD')
  return params

is_valid_places = (params) -> true if params.segments[0]?.destination and params.segments[0]?.origin

search_from_params = (should_replace_history = true) ->
  parsed_params = search_params.get()

  if parsed_params.flags && parsed_params.flags.utmDetail
    cookies.set('utmDetail', parsed_params.flags.utmDetail, new Date(Date.now() + 7 * 24 * 60 * 60 * 1000))
  else if cookies.get('utmDetail')
    parsed_params.flags ||= {}
    parsed_params.flags.utmDetail = cookies.get('utmDetail')

  # Parse cookies into params
  if (!parsed_params.segments[0].origin and !parsed_params.segments[0].origin_name) or (!parsed_params.segments[0].destination and !parsed_params.segments[0].destination_name)
    if !_isEmpty(parsed_params) and !!parsed_params.with_request
      metrics.reach_goal('NOT_ENOUGH_PARAMS', {path: window.location.search})
    _extend(parsed_params, cookie_params.get_params())

  # Currency
  defaultCurrency = window.TPWLCONFIG.currency

  converter.currency = currency = if preview_params_parser.is_preview()
    parsed_params.currency or defaultCurrency
  else
    parsed_params.currency or (cookies.get('currency') && cookies.get('currency').toLowerCase()) or defaultCurrency

  if currency.length > 3 && currency.indexOf('{') != -1
    try
      currencyJson = JSON.parse(currency)
      converter.currency = currency = currencyJson.code if currencyJson.code
    catch e
      console.error e

  dispatcher.send('currency_updated', currency.toLowerCase(), 'parser_parse')

  #Locale
  # defaultLocale = window.TPWLCONFIG.language
  # locale = LOCALE
  # dispatcher.send('locale_updated', locale.toLowerCase(), 'parser_parse')

  # Handle marker
  dispatcher.send 'marker_restored', marker.handle_marker(parsed_params.marker), 'parser_parse'

  if not is_valid_dates(parsed_params)
    if is_valid_places(parsed_params)
      parsed_params = update_params(parsed_params)
    else
      parsed_params.with_request = false
      if parsed_params.segments[0].date == "Invalid date" or typeof parsed_params.segments[0].date == "undefined"
        metrics.reach_goal('NO_DATES_URL', null)
        dispatcher.send 'no_dates_url'
        #Handle show_subscriptions
        if parsed_params['show_subscriptions']
          dispatcher.send 'subscriptions_restored', parsed_params
        return

      dispatcher.send('wrong_search_dates', null, 'parse')

  if parsed_params['noads'] and parsed_params['noads'] is 'true'
    cookies.set 'noads', true, 60*30
    dispatcher.send('noads', true)

  if parsed_params['with_request']
    dispatcher.send('start_search', {request_id: utils.generate_id(), params: parsed_params}, 'parser_parse')
  else
    dispatcher.send('search_not_started', null, 'parse')

  # We need to copy this object before processing places restorer, because
  # while waiting responses from autocomplete in restorer, some of subscribers
  # change parased params and "origin_name" of first segment become empty string.
  # To prevent this bug, or any of same bugs in future deep clone params for places restorer.
  copy_of_parsed_params = _cloneDeep(parsed_params)

  # Request autocomplete for places names.
  new PlacesRestorer(copy_of_parsed_params, (restored_params) ->
    dispatcher.send('places_restored', restored_params, 'parse')
  )

track_redirect_time = ->
  search_init_stamp = cookies.get('search_init_stamp')
  if search_init_stamp and +search_init_stamp > 0
    current_stamp = new Date().getTime()
    cookies.set('search_init_stamp', '0', 'Thu, 01 Jan 1970 00:00:01 GMT')
    diff = current_stamp - +search_init_stamp
    metrics.reach_goal('REDIRECT_TIME', null, diff)
    _gaq?.push(['_trackTiming', 'search_page', 'redirect_time', diff, undefined, 100])

search_from_params(true)
track_redirect_time()

module.exports = search_from_params
