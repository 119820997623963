import Params from 'flights/lib/params.js'
import DOMComponent from 'shared/components/base'

export default class Subscription extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'subscription',
      state: {
        language: LOCALE,
        permissions: {
          date: false,
          search_finished: false
        }
      }
    }
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
  }

  _initScript (element, params) {
    if (this.state.language !== 'ru') return element.parentNode.removeChild(element)

    var script = document.createElement('script')
    let originIata = (Params.parsed.origin && Params.parsed.origin.iata) || ''
    let originName = (Params.parsed.origin && Params.parsed.origin.name) || (params.segments[0] && params.segments[0].origin_name) || ''
    let destinationIata = (Params.parsed.destination && Params.parsed.destination.iata) || ''
    let destinationName = (Params.parsed.destination && Params.parsed.destination.name) || (params.segments && params.segments[0].destination_name) || ''
    let paramsString = Params.parsed ? `&originIata=${originIata}&originName=${originName}&destinationIata=${destinationIata}&destinationName=${destinationName}&depart_date=${Params.parsed.depart_date}` : ''
    if (paramsString.length && Params.parsed.return_date) { paramsString += `&return_date=${Params.parsed.return_date}` } else { paramsString += '&one_way=true' }

      script.src = `//www.travelpayouts.com/subscription_widget/widget.js?marker=${
        Params.affiliate.affiliate.marker
      }&host=${
        Params.affiliate.host
      }${paramsString}&highlight=true&powered_by=false&secondary=%23FFFFFF&primary=${encodeURIComponent(
        window.TPWLCONFIG.color_scheme.bg
      )}&special=%23EEEEEE&border_radius=4&view_type=compact`;
    element.appendChild(script)
    window.tpSubscribeHighlightCondition = () => {
      return this.state.permissions.date && this.state.permissions.search_finished
    }
    if (params.show_subscriptions) {
      var email = atob(params.show_subscriptions)
      const setTimer = (count) => {
        setTimeout(() => {
          if (count > 3) {
            return true;
          }
          if (document.querySelector('.tp-sbscr-widget-email-wrapper')) {
            document.querySelector('.tp-sbscr-widget-email-wrapper').childNodes[0].value = email
            document.querySelector('.tp-sbscr-widget-button-wrapper').childNodes[0].click()
            return true;
          }
          return setTimer(count + 1);
        }, 3000);
      };
      setTimer(1);
    }
  }

  _initEvents (dispatcher) {
    var shown = false
    dispatcher.on('subscriptions_restored', (event, restoredParams) => {
      if (!shown) {
        this._initScript(this.containerNode, restoredParams)
        shown = true
      }
    })
    dispatcher.on('places_restored', (event, restoredParams) => {
      if (!shown) {
        this._initScript(this.containerNode, restoredParams)
        shown = true
      }
    })
    dispatcher.on('start_search', (event, search) => { this._checkDate(search) })
    this._checkDate(Params.lastSearch)

    dispatcher.on('search_finished', (event, params) => {
      if (this.state.permissions.date && this.state.permissions.date == params.request_id) {
        this.state.permissions.search_finished = true
      }
    })

    dispatcher.on('systemMessageError', () => { this.hide() })
  }

  _initDOMEvents (view) {
    this._getWidgetElement(undefined)
  }

  _getWidgetElement (widgetWrapper) {
    if (widgetWrapper) {
      widgetWrapper.addEventListener('click', (event) => {
        if (!widgetWrapper.classList.contains('tp-sbscr--opened')) { widgetWrapper.classList.add('tp-sbscr--opened') }
      })
    } else {
      setTimeout(() => {
        widgetWrapper = document.querySelector('.tp-sbscr-widget')
        this._getWidgetElement(widgetWrapper)
      }, 500)
    }
  }

  _checkDate (search) {
    if (!search) return false
    let date = search.params.segments && search.params.segments[0] && new Date(search.params.segments[0].date)
    if ((date - new Date()) > 604800000) this.state.permissions.date = search.request_id
  }
}
